import React, { Component } from 'react';
import { Container, Grid, Typography, CardContent, CardActions } from '@material-ui/core';
import { GetData, PostData } from '../../api/service';
import AlumniCollege from './AlumniCollege';
import { Card, CardBody } from 'reactstrap';
import ShowImage from '../Department/ShowImage';
import { Menu, Button } from "antd";
import { Link } from "react-router-dom";
class AlumniDescription extends Component {
    render() {
        return (
            <div>
                {/* {this.props.type == 'association' ? (<Library title="Alumni Association" type="ALUMNI_ASSOCIATION" />) : null} */}

                {this.props.type == 'support' ? (<Library title="Alumni Support" type="COLLEGE_F_ALUMNI_SUPPORT" />) : null}
                {this.props.type == 'events' ? (<EventList title="Alumni events" type="COLLEGE_F_ALUMNI_EVENTS" />) : null}
                {this.props.type == 'committee' ? (<GoverningBody title="Alumni Association" type="ALUMNI_COMMITTEE" />) : null}
                
                
                {this.props.type == 'notable' ? (<LandProperty />) : null}
               
                {this.props.type == 'list' ? (<Building12 />) : null}
                {this.props.type == 'prominent' ? (<AlumniCollege />) : null}
                
            </div>
        );
    }
}

export default AlumniDescription;

export const SiteMap1 = (props) => {
    return (
        <div className="mb-4">
          <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
              Alumni Association
            </Typography>
            </div>
   
    )
}

export const SiteMap = (props) => {
    return (
        <div className="mb-4">
            <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
              Alumni Committee
            </Typography>

            

        </div>
    )
}

export const LandProperty = (props) => {
    return (
        <div className="mb-4">
            <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
                Notable Alumni
            </Typography>

            
        </div>
    )
}

export const Building = (props) => {
    return (
        <div className='mb-4'>
            <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
                Alumni Support
            </Typography>

            
        </div>
    )
}

export const Building12 = (props) => {

  const [data, setData] = React.useState([])
  const [isLoaded, setIsLoaded] = React.useState(false)

  React.useEffect(() => {
    GetData(`/getdepartments`).then((resp) => {
    setData(resp)
    setIsLoaded(true)
      
    })
  }, [])
  return (
      <div className='mb-4'>
          <Typography variant="h4" style={{ margin: "1em 0" }} gutterBottom>
              Alumni Members
          </Typography>


          {isLoaded && <div>
            
            <ul>
              {Array.isArray(data) && data.map((el,index) => <li key={index}>
                  <a style={{fontSize: 14}} targte="_blank" href={`/department/${el.dept_code}/alumni`}>Alumni Members of {el.dept_name}</a>
                </li>)}
              </ul>
            </div>}

          
      </div>
  )
}


export const Library = (props) => {

    const [data, setData] = React.useState([]);
    const [isLoaded, setIsLoaded] = React.useState(false)
  
    React.useEffect(() => {
      __loadFile()
    }, [])
  
    function __loadFile() {
      let d = {
        dept_code: 'PRAG',
        type: props.type,
        year: ""
      }
  
      PostData(`/getnoticesbytypedept`, d)
        .then((resp) => {
          setData(resp)
          setIsLoaded(true)
        })
    }
  
  
  
    return (
      <>
        
        <Container className="mb-5">
          <div className="text-justify">
            <Typography variant="h5" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
            {props.title}
            </Typography>
  
  
            {isLoaded && <div>
              {Array.isArray(data) && data.map((el, index) => <div key={index}>
              <Typography variant="h6" gutterBottom>
                  {el.title}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <p variant="body1" dangerouslySetInnerHTML={{ __html: el.description }} />
                </Typography>
  
                <br />
  
                <Grid container spacing={2}>
                  {Array.isArray(el.upload_info) && el.upload_info.map((el, index) => <Grid key={index} item xs={12} sm={12} md={12} lg={12}>
                    {el.fileType != "PDF" && <img
                      src={el.url}
                      style={{ width: '100%' }}
                    />}
  
  
                    {el.fileType == "PDF" && <a target="_blank" href={el.url}>{el.path}</a>}
                  </Grid>)}
                </Grid>
  
              </div>)}
            </div>}
          </div>
        </Container>
      </>
    );
  };


  export class GoverningBody extends Component {
    state = {
      data: [],
      isLoaded: false,
    };
  
    componentDidMount() {
      PostData(`/getmembersbytype`, {
        type: this.props.type
      }).then((resp) => {
        this.setState({
          data: resp,
          isLoaded: true,
        });
      });
    }
  
    render() {
      return (
        <div>
          
  
          <Container className="mb-5">
        
  
            <div>
              <Typography
                variant="h5"
                style={{ margin: "1em 0", fontWeight: 700 }}
                gutterBottom
              >
                {this.props.title}
              </Typography>
  
              <br />
  
              <div>
                {this.state.isLoaded && (
                  <>
                    <Grid container spacing={2}>
                      {this.state.data.map((el, index) => (
                        <Grid item xs={12} sm={12} md={6} lg={6} key={index}>
                          <Card>
                            <CardBody
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                            >
                              <div
                                style={{ display: "flex", alignItems: "center" }}
                              >
                                {Array.isArray(el.dp_url) &&
                                  el.dp_url.map((el1) => (
                                    <img src={el1} style={{ width: 150 }} />
                                  ))}
                              </div>
                              <div
                                style={{
                                  paddingLeft: 20,
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  variant="h6"
                                  style={{ fontWeight: 700 }}
                                  gutterBottom
                                >
                                  {el.name}
                                </Typography>
                                <Typography variant="overline">
                                  DESIGNATION
                                </Typography>
                                <Typography
                                  variant="body2"
                                  style={{ fontWeight: 700 }}
                                  gutterBottom
                                >
                                  {el.designation}
                                </Typography>
                                <br />
                                {el.email && <><Typography variant="overline">
                                  CONTACT
                                </Typography>
                                <Typography variant="body1">
                                  <a href={`mailto:${el.email.toLowerCase()}`}>
                                    {el.email.toLowerCase()}
                                  </a>
                                </Typography></>}

                                
                              </div>

                              <div
                                style={{
                                  paddingLeft: 20,
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                  marginTop: 40
                                }}
                              >
                                

                                
                                {el.phone && <><Typography variant="overline">
                                  PHONE
                                </Typography>
                                <Typography variant="body1">
                                  <a href={`tel:${el.phone}`}>
                                    {el.phone}
                                  </a>
                                </Typography></>}

                                <br />
                                {el.phone && <><Typography variant="overline">
                                  DATE OF JOINING
                                </Typography>
                                <Typography variant="body1">
                                  
                                    {el.date_of_joining}
                                 
                                </Typography></>}
                              </div>
                            </CardBody>
                          </Card>
                          <br />
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
              </div>
            </div>
          </Container>
        </div>
      );
    }
  }



export class EventList extends Component {
  state = {
    data: [],
    isLoaded: false,
    year: '',
  }

  __loadFile = (year) => {

    this.setState({
      isLoaded: false
    })

    let d = {
      dept_code: 'PRAG',
      type: this.props.type,
      year: ""
    }

    PostData(`/getnoticesbytypedept`, d)
      .then((resp) => {
        this.setState({
          data: resp,
          isLoaded: true
        })
      })
  }

  componentDidMount() {
    this.__loadFile();
  }

  setYear = (year) => {
    this.__loadFile(year)
  }

  render () {
    return <Container>
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{ paddingTop: "1em" }}
      >
         <Typography variant="h5" style={{ margin: "1em 0", fontWeight: 700 }} gutterBottom>
            {this.props.title}
            </Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>



        <br />
        {this.state.isLoaded ? (
          <Grid container spacing={2}>
            {this.state.data.map((el, index) =>
              <Grid item key={index} xs={12} sm={12} lg={6} md={6} style={{backgroundColor: "#f9f9f9", marginBottom: 10}}>
                <Card>
                  <div style={{ flexBasis: "40%" }}>
                    <ShowImage
                      files={el.upload_info}
                      nos="1"
                      height="300px"
                      width="100%"
                    />
                  </div>
                  <CardContent style={{ height: 80 }}>
                    <Typography
                      variant="h6"
                      style={{ fontSize: 14, fontWeight: 700, color: "#2C3E50" }}
                      gutterBottom
                    >
                      {el.title}
                    </Typography>
                    
                    {/* <Typography
                      variant="body2"
                      dangerouslySetInnerHTML={{ __html: el.description }}
                    >
                    </Typography>

                    {Array.isArray(el.upload_info) ? (
                      <div>
                        {el.upload_info.map((el1, index1) =>
                          <Button
                            size="small"
                            key={index1}
                            //onClick={this.handleClick.bind(this, el1)}
                            color="primary"
                            target="_blank"
                            href={el1.url}
                            style={{marginRight: 10}}

                          >
                            Attachment {index1 + 1}
                          </Button>
                        )}
                      </div>
                    ) : null} */}
                  </CardContent>
                  <CardActions>
                  <Link to={`/singleevent?eventId=${el.id}`}>
                      <Button type="primary">View Event</Button>
                    </Link>
                  </CardActions>



                </Card>
              </Grid>

            )}

            { }

            {
              this.state.data.length === 0 || this.state.data.length == 'undefined' ? (
                <Typography>No Events found</Typography>
              ) : null
            }

          </Grid>
        ) : <Typography>Loading . . . </Typography>}

      </Grid>
    </Grid>
  </Container>
  }
}