import React from "react";
import { PostData } from "../../api/service";
import { Divider, Card } from "antd";
import { CardContent, Container, Grid, Typography } from "@material-ui/core";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
const handleDragStart = (e) => e.preventDefault();

export default function ProminentSection() {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={3} lg={3}>
          <AlumniList />
        </Grid>
        <Grid item xs={12} sm={12} md={9} lg={9}>
          <div style={{backgroundColor: "#F9F9F9"}}>
          
          <Divider
        orientation="center"
        style={{ fontWeight: 700, fontSize: 20, borderColor: "#ABB2B9" }}
      >
        INSTITUTIONAL SWOC
      </Divider>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <SwocList
                type="COLLEGE_SWOC_S"
                title={"INSTITUTIONAL STRENGTH"}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <SwocList
                type="COLLEGE_SWOC_W"
                title={"INSTITUTIONAL WEAKNESS"}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <SwocList
                type="COLLEGE_SWOC_O"
                title={"INSTITUTIONAL OPPORTUNITY"}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3}>
              <SwocList
                type="COLLEGE_SWOC_C"
                title={"INSTITUTIONAL CHALLENGES"}
              />
            </Grid>
          </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export function AlumniList() {
  const [data, setData] = React.useState([]);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  function getDepartment() {
    PostData(`/getalumnibydept`, {
      dept_code: "PRAG",
    }).then((resp) => {
      setData(resp);
      setIsDataLoaded(true);
    });
  }

  React.useEffect(() => {
    getDepartment();
  }, []);
  return (
    <div>
      <Divider
        orientation="center"
        style={{ fontWeight: 700, fontSize: 20, borderColor: "#ABB2B9" }}
      >
        PROMINENT ALUMNI
      </Divider>
      <AliceCarousel
        responsive={{
          0: {
            items: 1,
          },
          1024: {
            items: 1,
            itemsFit: "contain",
          },
        }}
        autoPlay={true}
        autoWidth={true}
        autoHeight={true}
        autoPlayInterval={2000}
        dotsDisabled={true}
        mouseTracking
        items={data.map((el, index) => {
          return (
            <div>
              {Array.isArray(el.dp_url) && el.dp_url.length > 0 && (
                <img
                  src={el.dp_url[0]}
                  onDragStart={handleDragStart}
                  role="presentation"
                  style={{
                    width: "100%",
                    height: "400px",
                    objectFit: "contain",
                  }}
                />
              )}
              <br />
              <br />
              <Typography
                style={{
                  fontWeight: 700,
                  fontSize: 14,
                  marginTop: 20,
                  textAlign: "center",
                }}
              >
                {el.name}
              </Typography>
              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: 12,
                  marginTop: 20,
                  textAlign: "center",
                }}
              >
                {el.current_profession}
              </Typography>
              {el.qualification && (
                <Typography
                  style={{
                    fontWeight: 500,
                    fontSize: 12,
                    marginTop: 20,
                    textAlign: "center",
                  }}
                >
                  Qualification: {el.qualification}
                </Typography>
              )}
              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: 12,
                  marginTop: 20,
                  textAlign: "center",
                }}
              >
                Batch: {el.batch}
              </Typography>
            </div>
          );
        })}
      />
    </div>
  );
}

export function SwocList({ type, title }) {
  const [data, setData] = React.useState([]);
  const [isDataLoaded, setIsDataLoaded] = React.useState(false);

  function getDepartment() {
    PostData(`/getnoticesbytypedept`, {
      dept_code: "PRAG",
      type: type,
      year: "",
    }).then((resp) => {
      setData(resp);
      setIsDataLoaded(true);
    });
  }

  React.useEffect(() => {
    getDepartment();
  }, []);
  return (
    <div>
      {data.length > 0 && (
        <>
          <Divider
            orientation="center"
            style={{ fontWeight: 700, fontSize: 20, borderColor: "#ABB2B9" }}
          >
            {title}
          </Divider>
          <AliceCarousel
            responsive={{
              0: {
                items: 1,
              },
              1024: {
                items: 1,
                itemsFit: "contain",
              },
            }}
            autoPlay={true}
            autoWidth={true}
            autoHeight={true}
            autoPlayInterval={2000}
            dotsDisabled={true}
            mouseTracking
            items={data.map((el, index) => {
              return (
                <div>
                  {Array.isArray(el.upload_info) && el.upload_info.length > 0 && (
                    <img
                      src={el.upload_info[0].url}
                      onDragStart={handleDragStart}
                      role="presentation"
                      style={{
                        width: "100%",
                        height: "400px",
                        objectFit: "contain",
                      }}
                    />
                  )}
                  <br />
                  <br />
                  <Typography
                    style={{
                      fontWeight: 700,
                      fontSize: 14,
                      marginTop: 20,
                      textAlign: "center",
                    }}
                  >
                    {el.title}
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: 500,
                      fontSize: 12,
                      marginTop: 20,
                      textAlign: "center",
                    }}
                    dangerouslySetInnerHTML={{ __html: el.description }}
                  >
                    
                  </Typography>
                  
                </div>
              );
            })}
          />
        </>
      )}
    </div>
  );
}
